const LANG_CONFIG = [
  // English
  {
    id: "en",
    icon: "EnFlagIcon",
    name: "English (UK)",
    isDefault: true,
    URIPart: "",
  },
  // Indonesian
  {
    id: "id",
    icon: "IdFlagIcon",
    name: "Bahasa Indonesia",
    URIPart: "/id",
  },
];

const ARABIC_LANG_ID = "ar";

module.exports = {
  LANG_CONFIG,
  ARABIC_LANG_ID,
  uniqueList: LANG_CONFIG.map(({ id }) => id),
  list: LANG_CONFIG.map(({ id }) => id),
  defaultLangKey: LANG_CONFIG.find(({ isDefault }) => isDefault).id,
};
